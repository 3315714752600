<template>
  <div class="materialsWrap">
    <div class="searchTitle">
      <span class="label">素材名称：</span>
      <el-input
        v-model="searchValue"
        placeholder="请输入内容"
        clearable
      ></el-input>

      <span class="label">类型：</span>
      <el-select v-model="selectedType" placeholder="请选择" clearable>
        <el-option
          v-for="item in fileTypesOptions"
          :value="item.value"
          :label="item.lable"
          :key="item.id"
        />
      </el-select>

      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
    </div>

    <div class="materials">
      <el-row :gutter="20">
        <el-col :span="4" v-for="m in materialsData" :key="m.key">
          <TemplateC
            :template="m"
            :clickHandler="showUploadModal"
            type="delete"
            :deleteTemplate="deleteTemplate"
          />
        </el-col>
      </el-row>
    </div>

    <Pagination
      v-if="totalMaterials > 11"
      :handleSizeChange="handleSizeChange"
      :handleCurrentPageChange="handleCurrentPageChange"
      :total="totalMaterials"
      :pageSizes="[11, 23, 35, 47]"
    />

    <el-dialog
      title="上传素材"
      :visible="dialogVisible"
      width="40%"
      custom-class="uploadMaterial"
      @close="
        () => {
          this.dialogVisible = false;
          this.imageUrl = '';
        }
      "
    >
      <!-- <el-radio-group v-model="materailType">
        <el-radio-button
          :label="t.label"
          v-for="t in uploadMaterialTypes"
          :key="t.label"
        >
          {{ t.text }}
        </el-radio-button>
      </el-radio-group> -->
      <el-upload
        ref="uploadRef"
        multiple
        list-type="picture-card"
        action="/api/cloud/file/uploadFile"
        :data="uploadExtraParams"
        :headers="uploadHeaders"
        :accept="fileTypeAccepts[materailType]"
        class="apk-upload"
        :file-list="file"
        :auto-upload="false"
        :on-change="fileChange"
        :on-remove="fileRemove"
        :before-upload="beforeUpload"
        :on-success="successCall"
        :on-error="errorCall"
      >
        <i class="el-icon-plus"></i>
        <div slot="tip" class="el-upload__tip">支持批量上传，上传格式为{{fileTypeAccepts[materailType]}}文件</div>
      </el-upload>
      <!-- <input
        value=""
        type="file"
        id="photo"
        :accept="fileTypeAccepts[materailType]"
        capture="user"
        @change="onchange1"
      />

      <img
        v-if="imageUrl && materailType == 'image'"
        :src="imageUrl"
        class="avatar"
      />
      <label for="photo" v-else>
        <i class="el-icon-plus avatar-uploader-icon"></i
      ></label> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TemplateC from "@/components/Template";
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";

export default {
  components: {
    TemplateC,
    Pagination,
  },
  data() {
    return {
      searchValue: "",
      selectedType: "",
      materialsData: [],
      totalMaterials: 0,
      dialogVisible: false,
      uploadMaterialTypes: [
        // {
        //   text: "图片",
        //   label: "image",
        // },
        // {
        //   text: "视频",
        //   label: "video",
        // },
        // {
        //   text: "音频",
        //   label: "audio",
        // },
      ],
      materailType: "image",
      videoImage: require("@/assets/music.svg"),
      fileTypeAccepts: {
        image: ".png,.jpg,.jpeg,.gif",
        video: ".mp4",
        audio: ".mp3",
      },
      imageUrl: "",
      pictureFile: null,
      fileTypesOptions: [],
      file: [],
      fileSuccessArr: []
    };
  },
  computed: {
    ...mapState(["loginAccountInfo"]),
    uploadExtraParams() {
      let params = {
        file_type: this.materailType
      };
      return params;
    },
    uploadHeaders() {
      return {
        token: this.loginAccountInfo.token
      }
    }
  },
  watch: {
    materailType() {
      this.imageUrl = "";
    },
  },
  mounted() {
    this.getMaterialsTypes();
    this.queryMaterialsList({});
  },
  methods: {
    fileChange(file, fileList) {
        this.file = fileList;
    },
    fileRemove(file, fileList) {
      this.file = fileList;
    },
    beforeUpload() {
      this.fileSuccessArr = [];
      this.$changeLoadingState(true);
    },
    successCall(ret) {
      this.$changeLoadingState(false);
      if (ret && ret.status === "success") {
        this.fileSuccessArr.push(ret.data && ret.data.id || '');
        if (this.fileSuccessArr.length === this.file.length) {
          // 全部上传成功
          this.close();
          this.$emit('success');
        }
      } else {
        this.$message.error(ret && ret.msg);
      }
    },
    errorCall(ret) {
      this.$changeLoadingState(false);
      this.$message.error(ret && ret.msg);
    },
    uploadImage() {
      if (!this.file || this.file.length === 0) {
        this.$message.error('请先选择要上传的图片');
        return;
      }
      this.$refs.uploadRef.submit();
      // let formData = new FormData();
      // formData.append("file", this.pictureFile);
      // formData.append("file_type", this.materailType);
      // this.$request.uploadMaterial(formData).then((res) => {
      //   if (res.status != "success") {
      //     this.$message.error(res.msg);
      //     return;
      //   }
      //   this.$message.success("上传成功");
      //   this.queryMaterialsList({});
      //   this.dialogVisible = false;
      // });
    },
    onchange1(e) {
      const file = e.target.files[0];
      if (!file) return;
      this.pictureFile = file;
      if (!file.type.includes("image")) {
        Toast("只能插入图片");
        return;
      }

      const reader = new FileReader();
      reader.onload = (res) => {
        const img = new Image();
        const fileResult = res.target.result;
        img.onload = () => {
          this.imageUrl = fileResult;
        };
        img.src = fileResult;
      };
      reader.readAsDataURL(file);
    },
    getMaterialsTypes() {
      this.$request.getTypeOptions({ type: "file_type" }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.fileTypesOptions = res?.data || [];
      });
    },
    search() {
      this.queryMaterialsList({
        file_type: this.selectedType,
        file_name: this.searchValue,
      });
    },
    deleteTemplate(id) {
      this.$request.deleteMaterial({ id }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除成功");
        this.queryMaterialsList({});
      });
    },
    showUploadModal() {
      this.file = [];
      this.fileSuccessArr = [];
      this.dialogVisible = true;
    },
    queryMaterialsList({ page_size = 11, page_num = 1, file_name, file_type }) {
      this.$request
        .queryMaterialsList({ page_size, page_num, file_name, file_type })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          const data = (res?.data?.result || []).map((item) => ({
            id: item.id,
            picture: item.file_down_path,
            text: item.file_real_name,
            fileType: item.file_type,
          }));
          this.totalMaterials = res?.data?.total;
          this.materialsData = [
            ...data,
            {
              isAdd: true,
            },
          ];
        });
    },

    handleCurrentPageChange(v) {
      this.queryMaterialsList({ page_num: v });
    },
    handleSizeChange(v) {
      this.queryMaterialsList({ page_size: v });
    },
  },
};
</script>

<style lang="scss">
.materialsWrap {
  background: #fff;
  padding: 24px;
  .searchTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .label {
      margin-left: 16px;
    }
    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .materials {
    margin-top: 24px;
  }
}
.uploadMaterial {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  #photo {
    width: 0;
    height: 0;
  }
  .el-dialog__body {
    padding: 16px !important;
    text-align: center;
  }
  .avatar-uploader {
    display: flex;
    justify-content: center;
  }
  .el-radio-button__inner {
    padding: 14px 26px !important;
  }
  .avatar {
    width: 200px;
    height: 200px;
  }
  .audioWrap {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 40px;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
  }
}
</style>